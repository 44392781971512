<template>

  <div class="">
   
    <Modal
      ref="ImportModalCategory"
      id="ImportModalCategory"
      :title="`Import From ${category}` "
      size="md">
      <div class="mx-2">
        
        <div class="d-flex justify-content-center align-items-center gap flex-column">
            <div class="w-75">
            <div class="category-svg">
                <div class="outer-circle d-flex justify-content-center">
                    <div class="inner">
                        <svg v-if="category=='Shopify'" xmlns="http://www.w3.org/2000/svg" h="30" height="33" viewBox="0 0 256 292" preserveAspectRatio="xMidYMid"><path d="M223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-1.703-1.703-5.029-1.185-6.32-.805-.19.056-3.388 1.043-8.678 2.68-5.18-14.906-14.322-28.604-30.405-28.604-.444 0-.901.018-1.358.044C129.31 3.407 123.644.779 118.75.779c-37.465 0-55.364 46.835-60.976 70.635-14.558 4.511-24.9 7.718-26.221 8.133-8.126 2.549-8.383 2.805-9.45 10.462C21.3 95.806.038 260.235.038 260.235l165.678 31.042 89.77-19.42S223.973 58.8 223.775 57.34zM156.49 40.848l-14.019 4.339c.005-.988.01-1.96.01-3.023 0-9.264-1.286-16.723-3.349-22.636 8.287 1.04 13.806 10.469 17.358 21.32zm-27.638-19.483c2.304 5.773 3.802 14.058 3.802 25.238 0 .572-.005 1.095-.01 1.624-9.117 2.824-19.024 5.89-28.953 8.966 5.575-21.516 16.025-31.908 25.161-35.828zm-11.131-10.537c1.617 0 3.246.549 4.805 1.622-12.007 5.65-24.877 19.88-30.312 48.297l-22.886 7.088C75.694 46.16 90.81 10.828 117.72 10.828z" fill="#95BF46"/><path d="M221.237 54.983c-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-.637-.634-1.496-.959-2.394-1.099l-12.527 256.233 89.762-19.418S223.972 58.8 223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357" fill="#5E8E3E"/><path d="M135.242 104.585l-11.069 32.926s-9.698-5.176-21.586-5.176c-17.428 0-18.305 10.937-18.305 13.693 0 15.038 39.2 20.8 39.2 56.024 0 27.713-17.577 45.558-41.277 45.558-28.44 0-42.984-17.7-42.984-17.7l7.615-25.16s14.95 12.835 27.565 12.835c8.243 0 11.596-6.49 11.596-11.232 0-19.616-32.16-20.491-32.16-52.724 0-27.129 19.472-53.382 58.778-53.382 15.145 0 22.627 4.338 22.627 4.338" fill="#FFF"/></svg>
                  
                        <svg v-else-if="category=='Wix'" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 -0.558 77.894 30.921"><path d="M43.95.521C42.53 1.266 42 2.524 42 6c0 0 .72-.696 1.786-1.084a7.545 7.545 0 0 0 1.827-.992C46.814 3.044 47 1.91 47 0c0 0-1.958-.053-3.05.52M33.357 4.294l-4.114 16.02-3.423-13.11c-.333-1.394-.934-3.117-1.885-4.283-1.211-1.485-3.673-1.578-3.934-1.578-.263 0-2.725.093-3.935 1.578-.951 1.166-1.552 2.89-1.886 4.283l-3.422 13.112L6.644 4.294S6.28 2.539 5.038 1.438C3.022-.347 0 .034 0 .034l7.88 29.941s2.6.19 3.9-.478c1.708-.875 2.521-1.55 3.556-5.624.922-3.632 3.499-14.3 3.739-15.06.12-.375.27-1.272.926-1.272.67 0 .808.897.924 1.272.237.761 2.817 11.428 3.74 15.06 1.034 4.074 1.848 4.749 3.556 5.624 1.3.667 3.9.478 3.9.478L40 .035s-3.021-.382-5.037 1.403c-1.243 1.1-1.606 2.856-1.606 2.856zM46.997 5s-.46.763-1.507 1.392c-.674.405-1.32.678-2.013 1.035C42.315 8.025 42 8.692 42 9.709v20.244s1.854.251 3.067-.412c1.56-.854 1.919-1.678 1.933-5.387V6.08h-.003V5zm20.687 10.007L77.894.16s-4.308-.717-6.442 1.182c-1.365 1.215-2.893 3.4-2.893 3.4l-3.756 5.295c-.182.275-.422.575-.802.575-.38 0-.62-.3-.801-.575l-3.758-5.295s-1.526-2.185-2.892-3.4C54.417-.558 50.107.16 50.107.16l10.21 14.847-10.184 14.8s4.489.555 6.623-1.344c1.365-1.216 2.686-3.19 2.686-3.19l3.758-5.295c.18-.275.42-.575.801-.575.38 0 .62.3.802.575l3.756 5.294s1.4 1.975 2.765 3.191c2.134 1.9 6.543 1.345 6.543 1.345l-10.183-14.8z"/></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" v-else width="33" height="33" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Laag_1" x="0px" y="0px" viewBox="0 0 2489.2 2487.6"  xml:space="preserve">

<g>
	<path fill="#34313F" d="M1311.5,1416.2h314c89.3,0,145.8-49.1,145.8-128c0-74.4-56.5-128-145.8-128h-314c-10.4,0-19.3,8.9-19.3,17.9   v220.2C1293.6,1408.7,1301,1416.2,1311.5,1416.2z M1311.5,1923.6h324.4c99.7,0,159.2-50.6,159.2-139.9   c0-77.4-56.5-139.9-159.2-139.9h-324.4c-10.4,0-19.3,8.9-19.3,17.9v242.6C1293.6,1916.2,1301,1923.6,1311.5,1923.6z"/>
	<path fill="#34313F" d="M2460.3,5.5l-928.6,925.6h151.8c236.6,0,376.5,148.8,376.5,311c0,128-86.3,221.7-178.6,263.4   c-14.9,6-14.9,26.8,1.5,32.7c107.1,41.7,183,153.3,183,284.2c0,184.5-123.5,331.8-363.1,331.8h-657.7c-10.4,0-19.3-8.9-19.3-17.9   v-700.9L6.4,2450.4c-13.4,13.4-4.5,37.2,14.9,37.2h2450.9c8.9,0,16.4-7.4,16.4-16.4V18.9C2493,2.5,2472.2-6.4,2460.3,5.5z"/>
</g>
</svg>
                    </div>
                </div>
            </div>
            <div class="" v-if="IsLimitReached">
                <div class="category-header my-2">
                    Limit reached
                </div>
                <div class="category-description mb-2">
                    Only existing products will be updated. To import more, <span class="btn-link p-0 m-0 cursor-pointer" @click="$emit('upgradePlan')" >upgrade your plan</span>.
                </div>
            </div>
            <div class="" v-else>
                <div class="category-header my-2">
                    How would you like to import your products?
                </div>
                <div class="category-description mb-2">
                    Effortlessly import your products from your {{ category }} account with just a few clicks - it's like magic!
                </div>
            </div>
            <div class="d-flex gap justify-content-center">
                <slot></slot>
            
            </div>
        </div>
        </div>
    </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../Layout/Modal.vue';
export default {
components:{
    Modal,
},
props:{
    category:{
        type:String,
        default:''
    },
    IsLimitReached:Boolean
},
methods:{
    open(){
        this.$refs.ImportModalCategory.open();
    },
    close(){
        this.$refs.ImportModalCategory.close();
    }
}
}
</script>

<style>
.inner{
    display: flex;
    padding: 11px 13px 12px 13px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: var(--Elements-30-33px, 8px) solid rgba(0, 128, 96, 0.15);
    background: rgba(0, 128, 96, 0.30);
}
.category-header{
color: #101828;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}
.category-description{
    color: var(--Gray-500, #667085);
text-align: center;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
</style>